import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import './primary-content.scss';

import Container from '../container/container';
import { Link } from 'react-router-dom';
import { AppContext } from '../../helpers/Context';
import { getFunnelUrl } from '../../helpers/FunnelRedirection';

interface Props {
	title: string;
	subTitle: string;
}

const SlideContent: React.FC<Props> = ({ title, subTitle }) => {
	const { t } = useTranslation();
	const divStyle = {
		backgroundImage: `url('${t('slideContentImg')}')`,
	};

	const { branch } = useContext(AppContext);
	const linkTo = getFunnelUrl(branch);

	return (
		<div className="banner ctr" style={divStyle}>
			<Container>
				<div className="slide-content">
					<h1>{title}</h1>
					<p className="slide-p-padding">{subTitle}</p>
					<a rel="noopener noreferrer" href={linkTo} className="button ctr">
						Suscríbete ahora
					</a>
					<Link to="/servicios" className="mas-info underline">
						Más Información
					</Link>
				</div>
			</Container>
		</div>
	);
};

export default SlideContent;
