import React, { ReactNode, useContext } from 'react';
import './header.scss';

import CloseBtn from '../../../images/svg/close-btn.svg';
import { Link, useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalState';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

interface IProps {
	children?: ReactNode;
	className?: string;
	price?: string;
	progress?: string;
}

const FunnelHeader: React.FC<IProps> = ({ className, progress }) => {
	const { reset } = useContext(GlobalContext);
	const closeBtnClick = () => {
		reset('');
	};
	
	let history = useHistory();
	const onCloseFunnel = () => {
		MySwal.fire({
			title: '¿Realmente desea salir? La suscripción no se activa hasta que los datos estén completados',
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: `Continuar aquí`,
			denyButtonText: `Salir`,
			customClass: {
				confirmButton: 'button button--orange perfil-vehiculo-orange-btn mobile btnAlert',
				denyButton: 'button-disabled btnAlert'
			  },
			  buttonsStyling: false

		  }).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {

			} else if (result.isDenied) {
				history.push('/');
				return;
			}
		  })	  
	}
	return (
		<div>
			<div className="funnel-header-content desktop">
				<div className="funnel-header-content-left">
					<Link to="/">
						<span className="funnel-header-content-left-logo" />
					</Link>
				</div>
				<div className="funnel-header-content-right">
					<Link onClick={onCloseFunnel} to="#">
						<img className="funnel-header-content-right-close-btn" src={CloseBtn} onClick={closeBtnClick} alt="" />
					</Link>
				</div>
			</div>
			<div className={`funnel-header-container ${className}`}>
				<div className={`funnel-header-progress funnel-header-progress-${progress}`}></div>
			</div>
		</div>
	);
};

export default FunnelHeader;
