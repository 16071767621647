import React, { useContext, useEffect } from 'react';
import './finalizar.scss';
import OrangeButton from 'components/inputs/orange-button/orange-button';

import FunnelHeader from 'components/funnel/header/header';
import { Link, useHistory } from 'react-router-dom';
import FunnelFooterMobile from 'components/funnel/footer/mobile/funnel-footer-mobile';
import FunnelFooterDesktop from 'components/funnel/footer/desktop/funnel-footer-desktop';
import DoneImg from 'images/funnel/finalizar.svg';
import { AppContext } from 'helpers/Context';
import { GlobalContext } from 'context/GlobalState';
import { Helmet } from 'react-helmet';

export default function FunnelFinalizar() {
	const history = useHistory();
	const { setShowStatus } = useContext(AppContext);
	const { reset } = useContext(GlobalContext);

	const handleClickBtn = () => {
		history.push('/');
	};

	useEffect(() => {
		setShowStatus(false);
		return () => {
			setShowStatus(true);
		};
	}, [setShowStatus]);

	useEffect(() => {
		reset('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="App">
			<Helmet>
				<title>Finalizar tu compra - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="App-header">
				<FunnelFooterMobile
					className="mobile"
					classNameBtn="background-green"
					section="Suscripción Registrada"
					btnLabel="listo"
				/>
				<FunnelHeader progress="4" />
			</div>
			<div className="funnel__main">
				<div className="funnel__main__inner two-col full-width">
					<div className="funnel__main__inner__right illustration">
						<img className="finalizar-direction-column-img" src={DoneImg} alt="" />
					</div>

					<div className="funnel__main__inner__left text text-center-mobile v-ctr">
						<div className="inner">
							<h3>¡Listo!</h3>
							<p>
								Gracias por completar tu información. Tu suscripción se activará en 72 horas a partir de este momento.
								Te acabamos de enviar un correo electrónico con la confirmación de tu plan y el recibo.
							</p>
							<p>Ya puedes sentirte seguro y respaldado.</p>

							<div className="line"></div>

							<h4 className="finalizar-necesitas-ayuda">¿Tienes alguna pregunta o necesitas ayuda?</h4>

							<Link to="contacto" className="finalizar-contactenos">
								Contáctanos
							</Link>

							<OrangeButton handleClick={handleClickBtn} className="finalizar-green-btn full-width mobile">
								Listo
							</OrangeButton>
						</div>
					</div>
				</div>
			</div>
			<FunnelFooterDesktop
				className="desktop"
				classNameBtn="background-green"
				section="Suscripción Registrada"
				btnLabel="listo"
				handleClick={handleClickBtn}
			/>
		</div>
	);
}
