const getBranch = (data: string) => {
	let branch = '';

	switch (data) {
		case 'pr':
			branch = 'Puerto Rico';
			break;
		case 'cr':
			branch = 'Costa Rica';
			break;
		case 'pty':
			branch = 'Panama';
			break;
		case 'co':
			branch = 'Colombia';
			break;

		default:
			break;
	}

	return branch;
};

const getVehicleFormCountry = (branch: string, flagMany: boolean = false, capitalize: boolean = false) => {
	return (capitalize? 'M' : 'm' ) + (branch === 'cr' ? 'otocicleta' : 'otora') + (flagMany ? 's' : '');
};

export { getBranch, getVehicleFormCountry };
