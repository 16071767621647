import React, { useEffect, useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './footer.scss';
import instgram from '../../images/svg/instagram.svg';
import facebook from '../../images/svg/facebook.svg';
import iconLogo from '../../images/svg/logo-pin-grey.svg';
import { AppContext } from '../../helpers/Context';
import { useTranslation } from 'react-i18next';
import { defaultBranch } from 'helpers/Constants';
import { FunnelData } from 'interfaces/FunnelInterface';
import { GlobalContext } from 'context/GlobalState';

export default function Footer() {
	const { t, i18n } = useTranslation();

	const { branch, setBranchStatus } = useContext(AppContext);
	const branchChange = (value: any) => {
		const pathname = window.location.pathname;
		window.location.href = `${getUrlForCountry(value.target.value) + pathname}`;
	};

	let { search } = useLocation();
	const paramsString = decodeURIComponent(search);
	let params = new URLSearchParams(paramsString);
	const { funnel, setFunnel } = useContext(GlobalContext);

	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);

	useEffect(() => {
		i18n.changeLanguage(branch);
	}, [branch, i18n]);

	useEffect(() => {
		const country = getBranch();
		setBranchStatus(country);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		console.log('params => ', params);
		const utmSource = params.get('utm_source');
		const utmCampaign = params.get('utm_campaign');
		const utmId = params.get('utm_id');
		const utmMedium = params.get('utm_medium');

		funnelData.utm.utm_source = utmSource?.toString() || '';
		funnelData.utm.utm_campaign = utmCampaign?.toString() || '';
		funnelData.utm.utm_id = utmId?.toString() || '';
		funnelData.utm.utm_medium = utmMedium?.toString() || '';
		setFunnelData(funnelData);
		setFunnel(funnelData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [funnel]);

	const getBranch = () => {
		return (
			{
				'beta.cra.pr': 'pr',
				'connect.cr': 'cr',
				'connect.com.pa': 'pty',
				'connect.com.co': 'co',
			}[window.location.hostname] || defaultBranch
		);
	};
	const getUrlForCountry = (branch) => {
		return {
			pr: 'https://connect.pr',
			pty: 'https://connect.com.pa',
			cr: 'https://connect.cr',
			co: 'https://connect.com.co',
		}[branch || 'pr'];
	};
	return (
		<AppContext.Consumer>
			{({ showStatus }) => {
				if (showStatus) {
					return (
						<footer>
							<div className="footer__inner">
								<div className="footer__country-select">
									<img className="ft-logo-icon" src={iconLogo} alt="" />
									<label className="ft-dropdown-select">
										<select onChange={branchChange} name="options" value={branch}>
											<option value="pr">Puerto Rico</option>
											<option value="cr">Costa Rica</option>
											<option value="pty">Panamá</option>
											<option value="co">Colombia</option>
										</select>
									</label>
								</div>
								<div className="nav__cols">
									<nav>
										<h5>Ayuda</h5>
										<ul>
											<li>
												<Link to="contacto">Contáctanos</Link>
											</li>
											<li>
												<Link to="faq">FAQ</Link>
											</li>
											<br />
											<li>
												<a href={t('emailLink')}>{t('email')}</a>
											</li>
											<li>
												<a href={t('phoneLink')}>{t('phone')}</a>
											</li>
										</ul>
									</nav>
									<nav>
										<h5>Servicios</h5>
										<ul>
											<li>
												<Link to="/servicios">Servicios</Link>
											</li>
											<li>
												<Link to={`/terminos-y-condiciones/${branch}`}>Términos y condiciones</Link>
											</li>
										</ul>
									</nav>
									<nav>
										<h5>Compañía</h5>
										<ul>
											<li>
												<Link to="/sobre-nosotros">Sobre Connect</Link>
											</li>
											<li>
												<a rel="noopener noreferrer" target="_blank" href="https://jobs.lever.co/connectassistance">
													Trabaja con nosotros
												</a>
											</li>
											{['co', 'pr'].includes(branch) && (
												<li>
													<Link to="/politicas">Políticas y Condiciones</Link>
												</li>
											)}
											{branch === 'co' && (
												<li>
													<Link to="/politicas-seguridad-informatica">Política de Seguridad</Link>
												</li>
											)}
											{branch === 'co' && (
												<li>
													<Link to="/politicas-habeas-data">Política de Habeas Data</Link>
												</li>
											)}
										</ul>
									</nav>
									<nav>
										<h5>Síguenos</h5>
										<ul className="social">
											<li>
												<a href={`${t('instagram')}`} target="_blank" rel="noopener noreferrer">
													<img className="ft-social" src={instgram} alt="Instagram" />
												</a>
											</li>
											<li>
												<a href={`${t('facebook')}`} target="_blank" rel="noopener noreferrer">
													<img className="ft-social" src={facebook} alt="Facebook" />
												</a>
											</li>
										</ul>
									</nav>
								</div>
								<Link to="/">
									<span className="ft-logo" />
								</Link>
								<div className="ft-copyright">
									© 2011 - 2021 Connect Assistance. Todos los derechos reservados. | Política de Privacidad
								</div>
							</div>
						</footer>
					);
				}
				return '';
			}}
		</AppContext.Consumer>
	);
}
