import { utmData } from 'interfaces/FunnelInterface';
import api from './Api';

const createLead = async (branch: string, utm: utmData) => {
	return await api.post('/sales/cSalesFunel', { branch: branch, utm: utm });
};

const updateLead = async (data: any, utm: utmData) => {
	return await api.post('/sales/uSalesFunel', { _id: data._id, fields: data, utm: utm });
};

export { createLead, updateLead };
