const getFunnelUrl = (branch: String) => {
	switch (branch) {
		case 'pr':
			return 'https://lp.connect.pr/bf22';
		case 'cr':
			return 'https://lp.connect.cr/bf22';
		case 'pty':
			return '/pty-funnel';
		case 'co':
			return '/cobertura';

		default:
			return '/cobertura';
	}
};
const getFunnelUrlOtherServices = (branch: String) => {
	switch (branch) {
		case 'pr':
			return '/cobertura';
		case 'cr':
			return '/cobertura';
		case 'pty':
			return '/pty-funnel';
		case 'co':
			return '/cobertura';

		default:
			return '/cobertura';
	}
};

export { getFunnelUrl, getFunnelUrlOtherServices };
