import React, { useState } from 'react';
import { AppContext } from './Context';

export const useStatus = (): AppContext => {
	const [showStatus, setStatus] = useState(true);
	const [branch, setBranch] = useState('pr');
	const [identify, setIdentify] = useState(false);

	const setShowStatus = React.useCallback((currentStatus: boolean): void => {
		setStatus(currentStatus);
	}, []);

	const setIdentifyStatus = React.useCallback((currentStatus: boolean): void => {
		setIdentify(currentStatus);
	}, []);

	const setBranchStatus = React.useCallback((currentStatus: string): void => {
		setBranch(currentStatus);
	}, []);

	return {
		showStatus,
		setShowStatus,
		setBranchStatus,
		branch,
		identify,
		setIdentifyStatus,
	};
};
