import React from 'react';

import './subscribete.scss';
import CarImg from '../../../images/home/servicios/car-icon.png';
import MotoImg from '../../../images/home/servicios/moto-icon.png';
import HomeImg from '../../../images/home/servicios/home-icon.png';
import PricingHelpers from 'helpers/Pricing';
interface Props {
	title?: string;
	section?: string;
	linkTo: string;
	pricing: number;
	branch: string;
}

const ServiceSubscribete: React.FC<Props> = ({ title, section, linkTo, pricing, branch }) => {
	return (
		<div className="productos-buy-container">
			<div className="productos-buy-container-padding top">
				<div className="productos-buy-container-icon desktop">
					<img
						className="productos-buy-container-icon"
						alt=""
						src={section === 'auto' ? CarImg : section === 'moto' ? MotoImg : HomeImg}
					/>
				</div>
				<h3 className="productos-buy-container-h3">Consigue tu plan hoy</h3>
				<div className="productos-buy-container-info">Es fácil y toma solo minutos en completar.</div>
			</div>
			<div className="line"></div>
			<div className="productos-buy-container-padding bottom">
				<div className="productos-buy-container-price">
					Planes desde <span>${PricingHelpers.formatPrice(pricing, branch, 2)}</span> al mes.
				</div>
				<a rel="noopener noreferrer" href={linkTo} className="button ctr">
					Suscríbete
				</a>
			</div>
		</div>
	);
};

export default ServiceSubscribete;
