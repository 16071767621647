import React, { useContext, useEffect, useState } from 'react';
import './perfil-vehiculo.scss';
import OrangeButton from 'components/inputs/orange-button/orange-button';

import FunnelHeader from 'components/funnel/header/header';
import { makeStyles } from '@material-ui/core';
import FunnelFooterDesktop from 'components/funnel/footer/desktop/funnel-footer-desktop';
import FunnelFooterMobile from 'components/funnel/footer/mobile/funnel-footer-mobile';
import { AppContext } from 'helpers/Context';
import TextInput from 'components/inputs/textfield/textfield';
import DropDownInput from 'components/inputs/dropdown/dropdown';
import { useTranslation } from 'react-i18next';
import { getMakeService, getModelService } from 'services/VehicleService';
import LoadingDialog from 'helpers/LoadingDialog/loadingDialog';
import { getPastYears, motoMake } from 'helpers/Peril';
import { useFormik } from 'formik';
import { GlobalContext } from 'context/GlobalState';
import { updateItems } from 'services/PerfilService';
import Toaster from 'helpers/Toaster';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			marginTop: theme.spacing(0),
			width: '100%',
		},
		'& label.Mui-focused': {
			color: '#999ca0',
		},
	},

	tablilla: {
		width: '252px',
		float: 'left',
		'@media (max-width: 767px)': {
			width: '100%',
		},
	},
	fullWidth: {
		width: '100%',
	},
}));

interface IFormInputs {
	make: string;
	model: string;
	year: string;
	plate: string;
	color: string;
}

export default function FunnelPerfilVehiculo(props: any) {
	const { t } = useTranslation();
	const { setShowStatus, identify, setIdentifyStatus } = useContext(AppContext);
	const { funnel } = useContext(GlobalContext);
	const [isLoading, setIsLoading] = useState(false);
	const [formValues, setFormValues] = useState({
		make: '',
		model: '',
		year: '',
		plate: '',
		color: '',
	});
	const [make, setMake] = useState([]);
	const [model, setModel] = useState([]);
	const coverageYears: any = t('coverageYears');

	useEffect(() => {
		if (!identify) {
			window.analytics.identify();
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getMake = async (year: string) => {
		if (year && year !== '') {
			setIsLoading(true);
			const { data } = await getMakeService(year);
			return data.data;
		}
	};

	const getModel = async (make: string) => {
		setIsLoading(true);
		const { data } = await getModelService(formValues.year, make);
		return data.data;
	};

	useEffect(() => {
		setShowStatus(false);
		return () => {
			setShowStatus(true);
		};
	}, [setShowStatus, formValues]);

	const yearInputChange = async ({ target }: any) => {
		setMake([]);
		setMake(await getMake(target.value));
		setIsLoading(false);
		formik.setFieldValue('year', target.value);
		setFormValues({
			...formValues,
			[target.name]: target.value,
		});
	};

	const makeInputChange = async ({ target }: any) => {
		setModel([]);
		setModel(await getModel(target.value));
		setIsLoading(false);
		formik.setFieldValue('make', target.value);
		setFormValues({
			...formValues,
			[target.name]: target.value,
		});
	};

	const initialValues: IFormInputs = {
		make:
			props.countVehiculo === 1
				? funnel.subscriptionData.autoMake1
				: props.countVehiculo === 2
				? funnel.subscriptionData.autoMake2
				: props.countVehiculo === 3
				? funnel.subscriptionData.autoMake3
				: props.countVehiculo === 4
				? funnel.subscriptionData.autoMake4
				: funnel.subscriptionData.autoMake5,
		model:
			props.countVehiculo === 1
				? funnel.subscriptionData.autoModel1
				: props.countVehiculo === 2
				? funnel.subscriptionData.autoModel2
				: props.countVehiculo === 3
				? funnel.subscriptionData.autoModel3
				: props.countVehiculo === 4
				? funnel.subscriptionData.autoModel4
				: funnel.subscriptionData.autoModel5,
		year:
			props.countVehiculo === 1
				? funnel.subscriptionData.autoYear1
				: props.countVehiculo === 2
				? funnel.subscriptionData.autoYear2
				: props.countVehiculo === 3
				? funnel.subscriptionData.autoYear3
				: props.countVehiculo === 4
				? funnel.subscriptionData.autoYear4
				: funnel.subscriptionData.autoYear5,
		plate:
			props.countVehiculo === 1
				? funnel.subscriptionData.autoPlate1
				: props.countVehiculo === 2
				? funnel.subscriptionData.autoPlate2
				: props.countVehiculo === 3
				? funnel.subscriptionData.autoPlate3
				: props.countVehiculo === 4
				? funnel.subscriptionData.autoPlate4
				: funnel.subscriptionData.autoPlate5,
		color:
			props.countVehiculo === 1
				? funnel.subscriptionData.autoColor1
				: props.countVehiculo === 2
				? funnel.subscriptionData.autoColor2
				: props.countVehiculo === 3
				? funnel.subscriptionData.autoColor3
				: props.countVehiculo === 4
				? funnel.subscriptionData.autoColor4
				: funnel.subscriptionData.autoColor5,
	};

	useEffect(() => {
		// Using an IIFE
		(async function updateMake() {
			if (formik.values.year !== '') {
				setMake([]);
				setMake(await getMake(formik.values.year));
				formValues.year = formik.values.year;
				formValues.make = formik.values.make;
				formik.setFieldValue('year', formik.values.year);
				setModel([]);
				setModel(await getModel(formik.values.make));
				setIsLoading(false);
				formik.setFieldValue('make', formik.values.make);
			}
		})();
		// eslint-disable-next-line
	}, []);

	const validate = (values: any) => {
		const errors: any = {};
		if (!values.make) {
			errors.make = 'Campo Requerido';
		}
		if (!values.model) {
			errors.model = 'Campo Requerido';
		}
		if (!values.year) {
			errors.year = 'Campo Requerido';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues,
		validate,
		onSubmit: async (values) => {
			const key = props.component.section === 'auto' ? props.countVehiculo : props.countMotora;
			const section = props.component.section;
			const data = {
				_id: funnel.subscriptionData.mongoId,
				fields: {
					[`${section}Make${key}`]: formik.values.make,
					[`${section}Model${key}`]: formik.values.model,
					[`${section}Year${key}`]: formik.values.year,
					[`${section}Plate${key}`]: formik.values.plate,
					[`${section}Color${key}`]: formik.values.color,
				},
			};
			setIsLoading(true);
			const updateItem = await updateItems(data);

			if (updateItem.status && updateItem.data.status === false) {
				Toaster.error('Error');
				setIsLoading(false);
				return;
			}

			props.handleClick(formik.values, props.component);
		},
	});

	const classes = useStyles();

	return (
		<div className="App">
			<LoadingDialog isLoading={isLoading}></LoadingDialog>
			<div className="App-header">
				<FunnelFooterMobile
					className="mobile"
					section="Completar Perfil"
					linkTo="finalizar"
					showChevron={props.chevron}
					totalScreen={props.totalScreen}
					currentScreen={props.currentScreen}
				/>
				<FunnelHeader progress="4" />
			</div>
			<div className="funnel__main">
				<div className="funnel__main__inner">
					<form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
						<div className="perfil-vehiculo-content">
							<div className="perfl-1-direction-column">
								<div className="perfil-vehiculo-left-content">
									{props.messagePayment && <h3>Su pago ha sido procesado.</h3>}

									<h3>Completa la información de tu {props.component.section === 'auto' ? 'auto' : props.title}</h3>

									<div className="perfil-orange-title">
										{props.title} {props.component.section === 'auto' ? props.countVehiculo : props.countMotora}
									</div>

									<div className="perfil-form-content">
										<DropDownInput
											name="year"
											data={getPastYears(coverageYears)}
											onChange={props.component.section === 'auto' ? yearInputChange : formik.handleChange}
											className={`full-width`}
											label="Año"
											value={formik.values.year}
											errorinput={formik.errors.year ? 1 : 0}
											errorlabel={formik.errors.year}
										></DropDownInput>
										<DropDownInput
											name="make"
											data={props.component.section === 'auto' ? make : motoMake}
											onChange={props.component.section === 'auto' ? makeInputChange : formik.handleChange}
											className={`full-width`}
											value={formik.values.make}
											errorinput={formik.errors.make ? 1 : 0}
											errorlabel={formik.errors.make}
											disabled={false}
											label="Marca"
										></DropDownInput>

										{props.component.section === 'auto' ? (
											<DropDownInput
												name="model"
												data={model}
												onChange={formik.handleChange}
												className={`full-width`}
												label="Modelo"
												value={formik.values.model}
												errorinput={formik.errors.model ? 1 : 0}
												errorlabel={formik.errors.model}
												disabled={model.length === 0 ? true : false}
											></DropDownInput>
										) : (
											<TextInput
												name="model"
												className={`full-width`}
												label="Modelo"
												onChange={formik.handleChange}
												value={formik.values.model}
												errorinput={formik.errors.model ? 1 : 0}
												errorlabel={formik.errors.model}
											></TextInput>
										)}

										<TextInput
											name="plate"
											className={classes.tablilla}
											label={t('tablillaLabel')}
											onChange={formik.handleChange}
											value={formik.values.plate}
											errorinput={formik.errors.plate ? 1 : 0}
											errorlabel={formik.errors.plate}
										></TextInput>

										<TextInput
											name="color"
											className={classes.tablilla}
											label="Color"
											onChange={formik.handleChange}
											value={formik.values.color}
											errorinput={formik.errors.color ? 1 : 0}
											errorlabel={formik.errors.color}
										></TextInput>

										<OrangeButton className="perfil-vehiculo-orange-btn full-width mobile">
											{props.btnLabel}
										</OrangeButton>

										<div className="perfil-vehiculo-legal-content">
											<p>
												Una vez hayas añadido la información a tu perfil, tu suscripción quedará activa y lista para su
												uso luego de 72 horas.
											</p>
											<p>
												Si en el futuro necesitas cambiar o actualizar tu información, contáctanos y te ayudamos a
												realizar los cambios.
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className="perfil-vehiculo-direction-column">
								<div></div>
							</div>
						</div>
						<FunnelFooterDesktop
							className="desktop"
							section="Completar Perfil"
							linkTo="finalizar"
							btnLabel={props.btnLabel}
							totalScreen={props.totalScreen}
							currentScreen={props.currentScreen}
							component={props.component}
							handleClick={formik.handleSubmit}
							showChevron={props.chevron}
						/>
					</form>
				</div>
			</div>
		</div>
	);
}
