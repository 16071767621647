import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import './header-mobile.scss';
import closeBtn from '../../../images/svg/close-btn.svg';

import ChevronOrange from '../../../images/svg/chevron-orange.svg';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../helpers/Context';
import { getFunnelUrl } from '../../../helpers/FunnelRedirection';
import { getVehicleFormCountry } from '../../../helpers/Convertions';

export default function HeaderMobile() {
	const { t } = useTranslation();
	const [isNavVisible, setNavVisibility] = useState(false);
	const isChecked = true;
	const [isCheckedDrop1, setIsCheckedDrop1] = useState(false);
	const [isCheckedDrop2, setIsCheckedDrop2] = useState(false);
	const { branch } = useContext(AppContext);
	const linkTo = getFunnelUrl(branch);

	const targetScroll: any = null;

	const headerActiveClass = classNames(
		'header--mobile', // default classes on <nav>
		{
			active: isNavVisible, // classes added to <nav> when opened
		}
	);

	const handleInputChange = (event: any) => {
		const target = event.target;

		if (target.id === 'drop-1') {
			if (isCheckedDrop1) {
				setIsCheckedDrop1(false);
			} else {
				setIsCheckedDrop2(false);
				setIsCheckedDrop1(true);
			}
		} else if (target.id === 'drop-2') {
			if (isCheckedDrop2) {
				setIsCheckedDrop2(false);
			} else {
				setIsCheckedDrop1(false);
				setIsCheckedDrop2(true);
			}
		}
	};

	const disableScroll = () => {
		document.body.style.overflow = 'hidden';
	};

	const enableScroll = () => {
		document.body.style.overflow = targetScroll;
	};

	const closeMenu = () => {
		setNavVisibility(false);
		setIsCheckedDrop1(false);
		setIsCheckedDrop2(false);
		enableScroll();
	};

	const toggleNav = () => {
		disableScroll();
		setNavVisibility(!isNavVisible);
		if (isNavVisible) {
			closeMenu();
		}
	};

	const emergenciaClose = () => {
		document.body.classList.add('emergencia-closed');
	};

	return (
		<header className={headerActiveClass}>
			<div className="emergencia toast">
				<div className="emergencia__title">¿Tienes una emergencia?</div>
				<div className="emergencia__links">
					<a target="_blank" rel="noopener noreferrer" href={t('phoneLinkWhatsapp')} className="icon-btn chat">
						Chat
					</a>
					<a href={t('phoneLink')} className="icon-btn phone">
						Chat
					</a>
					<div className="link close" onClick={emergenciaClose}>
						<img src={closeBtn} alt="close" />
					</div>
				</div>
			</div>

			<div className="header__bar">
				<div className="menu-toggle">
					<i onClick={toggleNav} className={!isNavVisible ? 'open' : 'hidden'}></i>
					<i onClick={toggleNav} className={isNavVisible ? 'close' : 'hidden'}></i>
				</div>

				<div>
					<Link onClick={closeMenu} to="/" className="logo">
						<img src="../images/svg/Connect-Assistance--logo--icon.svg" alt="Connect Assistance Map Marker Icon" />
					</Link>
				</div>
			</div>

			<nav>
				<div className="nav__top">
					<div className="emergencia">
						<div className="emergencia__title">¿Tienes una emergencia?</div>
						<div className="emergencia__links">
							<Link to="#" className="icon-btn chat" />
							<a href={t('phoneLink')} className="icon-btn phone">
								Chat
							</a>
						</div>
					</div>

					<div className="main-menu">
						<input type="checkbox" id="drop" checked={isChecked} onChange={handleInputChange} />
						<ul>
							<li className="dd">
								<label htmlFor="drop-1" className="toggle">
									Servicios <img className="chevron" src={ChevronOrange} alt="" />
								</label>
								<input type="checkbox" id="drop-1" checked={isCheckedDrop1} onChange={handleInputChange} />
								{isCheckedDrop1 && (
									<ul>
										{branch === 'pr' && (
											<li>
												<Link onClick={closeMenu} to="/servicios">
													Servicios
												</Link>
											</li>
										)}

										<li>
											<Link
												onClick={closeMenu}
												to={['pr', 'cr', 'co'].includes(branch) ? '/servicios-shared/auto' : linkTo}
											>
												Asistencia para auto
											</Link>
										</li>
										{branch !== 'co' && (
											<li>
												<Link
													onClick={closeMenu}
													to={['pr', 'cr'].includes(branch) ? '/servicios-shared/motora' : linkTo}
												>
													Asistencia para {getVehicleFormCountry(branch)}
												</Link>
											</li>
										)}
										{branch === 'pr' && (
											<li>
												<Link onClick={closeMenu} to={'/servicios-shared/hogar'}>
													Asistencia en tu hogar
												</Link>
											</li>
										)}
									</ul>
								)}
							</li>
							<li className="dd">
								<label htmlFor="drop-2" className="toggle">
									Sobre Nosotros <img className="chevron" src={ChevronOrange} alt="" />
								</label>
								<input type="checkbox" id="drop-2" checked={isCheckedDrop2} onChange={handleInputChange} />
								{isCheckedDrop2 && (
									<ul>
										<li>
											<Link onClick={closeMenu} to="/sobre-nosotros">
												Sobre nosotros
											</Link>
										</li>
										{['co', 'pr'].includes(branch) && (
											<li>
												<Link onClick={closeMenu} to="/politicas">
													Políticas
												</Link>
											</li>
										)}
										<li>
											<Link onClick={closeMenu} to="/faq">
												FAQ
											</Link>
										</li>
										<li>
											<Link onClick={closeMenu} to="/contacto">
												Contacto
											</Link>
										</li>
									</ul>
								)}
							</li>
							<li>
								<a rel="noopener noreferrer" target="_blank" href="https://jobs.lever.co/connectassistance">
									Empleos
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div className="nav__bottom">
					<a rel="noopener noreferrer" href={linkTo} className="button full-width" onClick={closeMenu}>
						Suscríbete
					</a>
					<Link to="/" className="logo text-center">
						<img src="./images/svg/Connect-Assistance--logo--primary.svg" alt="Connect Assistance" />
					</Link>
				</div>
			</nav>
		</header>
	);
}
